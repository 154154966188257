import type { InfiniteData } from '@tanstack/query-core';
import type {
  ErrorResponse,
  PaginatedResponse,
  PaginatedResponseNoMeta,
  PaginatedResponseWithMeta,
} from 'src/interface/command-center/unsorted-types';
import { useOktaAuth } from '@okta/okta-react';
import { captureMessage } from '@sentry/react';
import {
  keepPreviousData as keepPreviousDataFn,
  QueryCache,
  QueryClient,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import compact from 'lodash/compact';
import { useCallback, useMemo } from 'react';
import { prepareRequest } from 'src/api/utils';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { QueryParams, QueryParamsWithHoles } from 'src/interface/utility';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import { useFailedRequestStore } from 'src/tools/hooks/useHasInternetConnectivity';
import { NonVoid, isNotNullish, isNullish } from 'src/tools/types';

const queryCache = new QueryCache();
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: { queries: { placeholderData: keepPreviousDataFn } },
});

const getNextCursor = (response: object | undefined) => {
  const nextCursor = response ? getMeta(response, 'next_cursor') : null;
  if (isNullish(nextCursor) || nextCursor === '') {
    return undefined;
  }
  return nextCursor;
};

// Payloads, by design, really can be any value
// TODO: create a solid JsonParsable typescript type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Result = any;
export type WrappedResult = { result: Result };
type WrappedErrors = { errors: ApiError[] };

export function getMeta<
  Result,
  Response extends Partial<PaginatedResponse<Result>>,
  Key extends keyof PaginatedResponseWithMeta<Result>['meta'] &
    keyof PaginatedResponseNoMeta<Result>,
>(response: Response | undefined, propName: Key) {
  if (!response || typeof response === 'string') {
    return undefined;
  }
  let value;
  if ('meta' in response) {
    value = response.meta?.[propName];
  } else {
    value = response[propName as keyof PaginatedResponse<Result>];
  }
  // TODO: RTI-3343 Work out how to not need this cast
  return value as Response extends PaginatedResponseWithMeta<Result> ?
    PaginatedResponseWithMeta<Result>['meta'][Key]
  : PaginatedResponseNoMeta<Result>[Key];
}

function hasMeta<
  Result,
  Response extends Partial<PaginatedResponse<Result>>,
  Key extends keyof PaginatedResponseWithMeta<Result>['meta'] &
    keyof PaginatedResponseNoMeta<Result>,
>(response: Response | undefined, propName: Key) {
  if (!response || typeof response === 'string') {
    return undefined;
  }

  if ('meta' in response) {
    return response.meta && propName in response.meta;
  } else {
    return propName in response;
  }
}

function isWrappedResult(response: Result): response is WrappedResult {
  return response?.['result'] !== undefined;
}

function isWrappedErrors(response: Result): response is WrappedErrors {
  return response?.['errors'] !== undefined;
}

type ResponseTypeDefinition =
  | XMLHttpRequestResponseType
  | {
      default: XMLHttpRequestResponseType;
      [Status: number]: XMLHttpRequestResponseType;
    };
type RequestDataProps = {
  method?: string;
  responseType?: ResponseTypeDefinition;
};
type RequestDataResponse<Body> = {
  body?: Body;
  params?: QueryParams;
  /**
   * This MUST be true when calling an external url
   * Not calling this causes our sensitive bearer auth token to be sent.
   */
  excludeAuth?: boolean;
};

const fillParamHoles = (paramsWithHoles?: QueryParamsWithHoles) =>
  (
    paramsWithHoles &&
    Object.values(paramsWithHoles).filter((value) => value !== undefined).length
  ) ?
    (Object.fromEntries(
      Object.entries(paramsWithHoles).filter(
        ([, value]) => value !== undefined,
      ),
    ) as QueryParams)
  : undefined;

type BuildQueryKeyProps = {
  queryKey?: QueryKey;
  url?: string;
  params?: QueryParams;
};
type BuildQueryKeyPropsWithQueryKey = BuildQueryKeyProps & {
  queryKey: QueryKey;
};
type BuildQueryKeyPropsWithoutQueryKey = BuildQueryKeyProps & {
  url: string;
};
const isQueryKeyPropsWithQueryKey = (
  props: BuildQueryKeyProps,
): props is BuildQueryKeyPropsWithQueryKey => !!props.queryKey;
const buildQueryKey = (
  props: BuildQueryKeyPropsWithQueryKey | BuildQueryKeyPropsWithoutQueryKey,
) => {
  return (
    isQueryKeyPropsWithQueryKey(props) ? props.queryKey
    : props.params && Object.values(props.params).length ?
      [
        props.url,
        JSON.stringify(
          Object.fromEntries(
            Object.entries(props.params).sort(([keyA], [keyB]) =>
              keyA.localeCompare(keyB),
            ),
          ),
        ),
      ]
    : [props.url]
  );
};

/**
 * useRequestData hook
 *
 * used for abstracting application specific parts from the rest of the hooks
 * Handles API authentication
 * should generally only be used inside another api hook and not by itself
 *
 * @param url          - the URL of the resource requested
 * @param method       - the HTTP method used
 * @param responseType - the expected response type (default JSON)
 */
// TODO: RTI-3343 enforce matching `responseType` to Payload type
export const useRequestData = <Payload, Body = void>(
  url: string,
  {
    method = 'GET',
    responseType: responseTypeProps = 'json',
  }: RequestDataProps,
): (({
  body,
  params,
  excludeAuth,
}: RequestDataResponse<Body>) => Promise<Payload>) => {
  const { oktaAuth } = useOktaAuth();
  const { setLatestFailedRequests } = useFailedRequestStore();

  return async ({ body, params, excludeAuth }) => {
    const token = (excludeAuth ? '' : oktaAuth.getAccessToken()) || '';
    const { serializedBody, headers, serializedUrl } = prepareRequest(url, {
      params,
      body,
      token,
    });

    let response: Response | undefined;
    let error: ApiError | undefined;
    const responseTimeout = setTimeout(
      () => {
        setLatestFailedRequests((latestFailedRequests) => [
          ...latestFailedRequests,
          serializedUrl,
        ]);
      },
      inMilliseconds(30, 'seconds'),
    );
    try {
      clearTimeout(responseTimeout);
      response = await fetch(serializedUrl, {
        method,
        headers,
        body: serializedBody,
      });
      setLatestFailedRequests(() => []);
    } catch (_error) {
      clearTimeout(responseTimeout);

      error = new ApiError(
        0,
        `Failed to fetch ${url}: ${
          (_error as Error).message || 'Unexpected error'
        }`,
      );

      if ((_error as Error)?.message === 'Load failed') {
        setLatestFailedRequests((latestFailedRequests) => [
          ...latestFailedRequests,
          serializedUrl,
        ]);
      }
    }

    if (!response?.ok) {
      if (response) {
        try {
          const clonedResponse = response.clone();
          const rawError: ErrorResponse = await clonedResponse.json();

          const normalisedError =
            isNullish(rawError) ? null
            : typeof rawError === 'string' ? rawError
            : 'message' in rawError ? rawError
            : rawError.error;

          const errorMessage =
            isNullish(normalisedError) ? 'Unexpected error'
            : typeof normalisedError === 'string' ? normalisedError
            : normalisedError.message;

          const { code, errors } =
            isNullish(normalisedError) || typeof normalisedError === 'string' ?
              { code: undefined, errors: undefined }
            : normalisedError;

          error = new ApiError(
            clonedResponse.status,
            errorMessage,
            code,
            errors,
          );
        } catch (_error) {
          // TODO: better handle api errors without lots of cloning and “try/catch”ing
        }

        if (!error) {
          try {
            const _error = await response.text();

            if (_error) {
              error = new ApiError(
                response.status,
                _error ?? 'Unexpected error',
              );
            }
          } catch (_error) {
            // TODO: better handle api errors without lots of cloning and “try/catch”ing
          }
        }

        if (!error) {
          error = new ApiError(response.status, 'Unexpected error');
        }
      }

      throw error;
    }

    let result;
    try {
      const clonedResponse = response.clone();
      const responseType =
        typeof responseTypeProps === 'string' ? responseTypeProps
        : response.status in responseTypeProps ?
          responseTypeProps[response.status]
        : responseTypeProps.default;

      switch (responseType) {
        case 'arraybuffer':
          result = await clonedResponse.arrayBuffer();
          break;
        case 'json':
          result = await clonedResponse.json();
          break;
        case 'blob':
          result = await clonedResponse.blob();
          break;
        case 'text':
        default:
          result = await clonedResponse.text();
          break;
      }
      return result;
    } catch (_error) {
      result = await response.text();
      return result;
    }
  };
};

// TODO: RTI-3343 Let’s make a separate hook for external URL calls
//    and make it so we can’t use useApi for internal calls
type ApiProps<Params extends QueryParamsWithHoles | undefined> = {
  queryKey?: string[];
  params?: Params;
  responseType?: ResponseTypeDefinition;
  refetchInterval?: number | false;
  keepPreviousData?: boolean;
  staleTime?: number;
  retry?: number | boolean;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean | 'always';
  // TODO: remove as many instances of this as possible: RTI-596
  isKnownToBeAnIrregularApi?: boolean;
  knownErrorCodes?: string[];
  /**
   * THIS MUST BE USED WHEN CALLING AN EXTERNAL URL
   * Not calling this causes our sensitive bearer auth token to be sent.
   */
  isExternalUrl?: boolean;
};

/**
 * useApi hook
 *
 * also see: https://react-query.tanstack.com/docs/api#usequery
 *
 * used for requesting data from an API - uses react-query
 * e.g.: getting data from an arbitrary url:
 *
 * inside your component:
 *
 * ```ts
 * function MyComponent() {
 *   const {data, status, error} = useApi("some_endpoint");
 *
 *   if (status === 'success') {
 *     // data succesfully fetched (available inside data)
 *     // do whatever you want `data`
 *   }
 *
 *   if (status === 'pending') {
 *     // request is loading - maybe show a loading indicator
 *   }
 *
 *   if (status === 'error') {
 *     // request failed, handle error here
 *     // `error` contains the error from the API
 *     // fails both for API errors (HTTP status not ok)
 *     // or any other thrown error
 *   }
 * }
 * ```
 *
 * @param url          - URL of the requested resource
 * @param params       - any request params (optional)
 * @param responseType - expected response type (default JSON)
 */
export const useApi = <
  ResponseOrResult,
  Params extends QueryParamsWithHoles | undefined = undefined,
>(
  url: string,
  {
    params: paramsWithHoles,
    responseType = 'json',
    refetchInterval,
    queryKey: queryKeyProps,
    keepPreviousData = false,
    staleTime = inMilliseconds(5, 'minutes'),
    retry = 3,
    enabled = true,
    refetchOnWindowFocus,
    isKnownToBeAnIrregularApi = false,
    knownErrorCodes,
    isExternalUrl = false,
  }: ApiProps<Params> = {},
) => {
  const params = useMemo(
    () => fillParamHoles(paramsWithHoles),
    [paramsWithHoles],
  );

  const queryKey = useMemo(
    () => buildQueryKey({ queryKey: queryKeyProps, url, params }),
    [queryKeyProps, url, params],
  );

  const requestData = useRequestData<ResponseOrResult>(url, {
    responseType,
  });

  const {
    data: queryResponse,
    status,
    error,
    refetch,
    isFetching,
    isFetched,
  } = useQuery<ResponseOrResult, ApiError>({
    queryKey,
    queryFn: () => requestData({ params, excludeAuth: !!isExternalUrl }),
    refetchInterval,
    placeholderData: keepPreviousData ? keepPreviousDataFn : undefined,
    staleTime,
    retry:
      knownErrorCodes?.length ?
        (failureCount, error) => {
          if (
            !retry ||
            (typeof retry === 'number' && failureCount < retry) ||
            (error?.code && knownErrorCodes.includes(error?.code))
          ) {
            return false;
          }
          return true;
        }
      : retry,
    enabled,
    refetchOnWindowFocus,
  });

  if (
    !isKnownToBeAnIrregularApi &&
    url &&
    queryResponse &&
    hasMeta(queryResponse, 'next_cursor') &&
    isNotNullish(getNextCursor(queryResponse))
  ) {
    captureMessage(`wrongly used useApi: ${url} is paginated`);
  }

  type Result =
    ResponseOrResult extends { result: infer Payload } ? Payload
    : ResponseOrResult;
  const result: Result | undefined =
    queryResponse && isWrappedResult(queryResponse) ?
      queryResponse.result
    : queryResponse;
  const errors =
    queryResponse && isWrappedErrors(queryResponse) ?
      queryResponse.errors
    : undefined;

  const total =
    getMeta(
      Array.isArray(queryResponse) ? queryResponse[0] : queryResponse,
      'total',
    ) ?? (Array.isArray(result) ? result.length : null);

  return useMemo(
    () => ({
      result,
      status,
      error,
      warnings: errors,
      isFetching,
      isFetched,
      total,
      refetch,
    }),
    [result, status, error, errors, isFetching, isFetched, total, refetch],
  );
};

type InfiniteApiProps<Params extends QueryParamsWithHoles | undefined> = {
  params?: Partial<Params>;
  responseType?: ResponseTypeDefinition;
  pageSize?: number;
  queryKey?: string[];
  keepPreviousData?: boolean;
  staleTime?: number;
  retry?: number | boolean;
  enabled?: boolean;
  refetchInterval?: number | false;
  refetchOnWindowFocus?: boolean | 'always';
  // TODO: remove as many instances of this as possible: RTI-596
  isKnownToBeAnIrregularApi?: boolean;
  knownErrorCodes?: string[];
};

/**
 * useInfiniteApi hook
 *
 * also see: https://react-query.tanstack.com/docs/api#useinfinitequery
 *
 * used for requesting data from a paginated API in a 'infinite loading' way
 * used similar to useApi with some extra functionality (described below)
 *
 * ```ts
 * function MyComponent() {
 *   const {data, status, error, canFetchMore, fetchMore} = useInfiniteApi("some_endpoint");
 *
 *   // same as useApi for status/data/error
 *
 *   const onBottomReached = () => {
 *      // check if we have a next page
 *      if (canFetchMore) {
 *      // trigger the next fetch which will append to `data`
 *      // and trigger a rerender
 *        fetchMore();
 *      }
 *   }
 * }
 * ```
 *
 * @param url          - URL of the requested resource
 * @param params       - any request params (optional)
 * @param responseType - expected response type (default JSON)
 */
export const useInfiniteApi = <
  Response extends { result: Result[] },
  Params extends QueryParamsWithHoles | undefined =
    | QueryParamsWithHoles
    | undefined,
>(
  url: string,
  {
    params: paramsWithHoles,
    responseType = 'json',
    pageSize = 25,
    queryKey: queryKeyProps,
    keepPreviousData = false,
    staleTime = inMilliseconds(5, 'minutes'),
    retry = 3,
    enabled = true,
    refetchInterval = false,
    isKnownToBeAnIrregularApi = false,
    knownErrorCodes,
    refetchOnWindowFocus,
  }: InfiniteApiProps<Params> = {},
) => {
  type Payload = Response['result'][number];

  const params = useMemo(
    // I don’t know why we need this `as` conversion
    () => fillParamHoles(paramsWithHoles as QueryParamsWithHoles | undefined),
    [paramsWithHoles],
  );

  const queryKey = useMemo(
    () => buildQueryKey({ queryKey: queryKeyProps, url, params }),
    [queryKeyProps, url, params],
  );

  const requestData = useRequestData<PaginatedResponse<Response>>(url, {
    responseType,
  });

  const {
    data: queryResponse,
    status,
    hasNextPage: canFetchMore,
    fetchNextPage,
    refetch,
    error,
    isFetching,
  } = useInfiniteQuery<
    PaginatedResponse<Payload>,
    ApiError,
    InfiniteData<PaginatedResponse<Payload>>,
    QueryKey,
    string | undefined
  >({
    queryKey,
    queryFn: ({ pageParam }) => {
      const newParams: QueryParams = { ...params };
      if (pageSize > 0) {
        newParams.limit = pageSize;
      }
      if (pageParam) {
        newParams.cursor = pageParam;
      }
      return requestData({ params: newParams });
    },
    initialPageParam: params?.cursor as string | undefined,
    getNextPageParam: (lastPage) => getNextCursor(lastPage),
    placeholderData: keepPreviousData ? keepPreviousDataFn : undefined,
    staleTime,
    retry:
      knownErrorCodes?.length ?
        (failureCount, error) => {
          if (
            !retry ||
            (typeof retry === 'number' && failureCount < retry) ||
            (error?.code && knownErrorCodes.includes(error?.code))
          ) {
            return false;
          }
          return true;
        }
      : retry,
    enabled,
    refetchInterval,
    refetchOnWindowFocus,
  });

  if (
    !isKnownToBeAnIrregularApi &&
    url &&
    queryResponse &&
    !hasMeta(queryResponse.pages[0], 'next_cursor') &&
    getNextCursor(queryResponse.pages[0]) !== null
  ) {
    captureMessage(`wrongly used useInfiniteApi: ${url} is not paginated`);
  }

  const payload = useMemo<{
    result: Payload[] | undefined;
    total: number | null;
  }>(() => {
    let data = queryResponse?.pages;

    // TODO: work out why paginated responses sometimes return single objects
    if (data && isWrappedResult(data)) {
      data = [data] as PaginatedResponse<Payload>[];
      captureMessage(`Data returned from ${url} didn’t get wrapped`);
    }

    const result =
      Array.isArray(data) ? data.flatMap((group) => group.result) : undefined;

    const total =
      getMeta(Array.isArray(data) ? data[0] : data, 'total') || null;

    return { result, total };
  }, [url, queryResponse]);

  const fetchMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const returnObject = useMemo(
    () => ({
      result: payload.result ? compact(payload.result) : undefined,
      total: payload.total,
      status,
      fetchMore,
      refetch,
      error,
      canFetchMore,
      isFetching,
      queryKey,
    }),
    [
      canFetchMore,
      payload,
      error,
      fetchMore,
      isFetching,
      refetch,
      status,
      queryKey,
    ],
  );

  return returnObject;
};

type MutateApiProps<Result, Body, Params extends Record<string, string>> = {
  params?: Params;
  onSuccess?: (result: Result, variables: Body) => void;
  responseType?: ResponseTypeDefinition;
};

/**
 * useMutateApi hook
 *
 * also see: https://react-query.tanstack.com/docs/api#usemutation
 *
 * used for issuing a mutation.
 * can be used for optimistic updates for existing resources (check react-query)
 * or it can trigger a refetch for the resource
 *
 * ```ts
 * function SomeComponent() {
 *   const {mutate, status, data, error} = useMutateApi('resource_url', 'POST');
 *
 *   const onClick = () => {
 *     mutate(newResourceValue);
 *   }
 *
 *   // handle status/data/error similar to useApi/useInfiniteApi
 *
 *   if (status === 'success') {
 *     // mutation was successful
 *   }
 *
 *   if (status === 'error') {
 *     // mutation failed
 *   }
 * }
 * ```
 *
 * @param params  - any params (optional)
 */
export const useMutateApi = <
  Response,
  Body,
  Params extends Record<string, string> = Record<string, string>,
>(
  url: string,
  method: string,
  {
    params,
    onSuccess,
    responseType,
  }: /* NonVoid wraps Body to allow calling mutate without parameters when
     /*   the expected type coming from swagger is void */
  MutateApiProps<Response, NonVoid<Body>, Params> = {},
) => {
  const requestData = useRequestData<Response, NonVoid<Body>>(url, {
    method,
    responseType,
  });
  const mutationFn = useCallback(
    (body: NonVoid<Body>) => requestData({ params, body }),
    [params, requestData],
  );
  const {
    mutateAsync: mutate,
    status,
    error,
    data,
    isPending: isLoading,
    isSuccess,
    reset,
  } = useMutation<Response, ApiError, NonVoid<Body>>({
    mutationFn: mutationFn,
    onSuccess,
  });

  return useMemo(
    () => ({
      response: data,
      status,
      error,
      isLoading,
      isSuccess,
      mutate,
      reset,
    }),
    [data, error, isLoading, isSuccess, mutate, reset, status],
  );
};
